<template>
  <div class="container">
    <div class="content">
      <div class="item item_1">
        <div
          class="map-container"
          style="width: 100%; height: 100%"
          ref="myEchart"
        ></div>
        <div class="mapTips">
          <p>绿灯分值 <span> >80分 </span></p>
          <p>黄灯分值 <span> 60-80分 </span></p>
          <p>红灯分值 <span> <60分 </span></p>
        </div>
      </div>
      <div class="item item_3">
        <div class="title">得分公式</div>
        <div class="detail">
          <div class="liuD">
            <div class="liuItem">
              <div class="liuChart" ref="chart1"></div>
              <div class="liuTitle">接待得分<br />(A)</div>
            </div>
            <div class="liuAlg">=</div>
            <div class="liuItem">
              <div class="liuChart" ref="chart2"></div>
              <div class="liuTitle">
                系统使用得分<br />B({{ scorePercent[0] }})
              </div>
            </div>
            <div class="liuAlg">+</div>
            <div class="liuItem">
              <div class="liuChart" ref="chart3"></div>
              <div class="liuTitle">展厅得分<br />C({{ scorePercent[1] }})</div>
            </div>
            <div class="liuAlg">+</div>
            <div class="liuItem">
              <div class="liuChart" ref="chart4"></div>
              <div class="liuTitle">网销得分<br />D({{ scorePercent[2] }})</div>
            </div>
          </div>
          <!-- <div
            ref="liu"
            style="with: 100px; height: 100px; border: 1px solid red"
          ></div> -->
        </div>
      </div>
      <div class="item item_2">
        <div class="title">核心指标</div>
        <div class="detail">
          <div
            class="itemDetail"
            v-for="(item, index) in entryData"
            :key="index"
          >
            <div class="label" :class="['icon_' + (index + 1)]">
              {{ item.label }}
            </div>
            <div class="desc">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="item item_4">
        <table class="table" cellspacing="0" cellspadding="0">
          <tr>
            <th></th>
            <th>排行</th>
            <th>接待得分</th>
            <th>系统使用得分</th>
            <th>展厅得分</th>
            <th>网销得分</th>
          </tr>
          <tr v-for="item in regionData" :key="item.id">
            <td @click="jumpToRegion(item)">{{ item.name }}</td>
            <td>{{ item.rank }}</td>
            <td><span :style="getBgColor(item.total)">{{ item.total }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.system)">{{ item.system }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.hall)">{{ item.hall }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.web)">{{ item.web }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div class="item item_5">
        <table class="table" cellspacing="0" cellspadding="0">
          <tr>
            <th></th>
            <th>排行</th>
            <th>接待得分</th>
            <th>系统使用得分</th>
            <th>展厅得分</th>
            <th>网销得分</th>
          </tr>
          <tr v-for="item in areaData" :key="item.id">
            <td @click="jumpToArea(item)">{{ item.name }}</td>
            <td>{{ item.rank }}</td>
            <td><span :style="getBgColor(item.total)">{{ item.total }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.system)">{{ item.system }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.hall)">{{ item.hall }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.web)">{{ item.web }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { postData } from "@/api/index";
import echarts from "echarts";
import echartsLiquidfill from "echarts-liquidfill";
import "../../../node_modules/echarts/map/js/china.js";
import nameMap from "@/utils/nameMap.js";
import { jump, getLightColor } from "@/utils/index";
export default {
  data() {
    return {
      entryData: [],
      regionData: [],
      areaData: [],
      scoreData: [],
      scorePercent: {},
    };
  },
  mounted() {
    this.getDataBySearch();
    // this.liquidFill();
  },
  methods: {
    async getDataBySearch() {
      const query = { is_mobile: 1, ...this.$store.state.query };
      console.log(query);
      this.loading = true;
      const { data = {} } = await postData("/api/board/country", query);
      console.log(data);
      const {
        area,
        region,
        province,
        core,
        a,
        b,
        c,
        d,
        b_percent,
        c_percent,
        d_percent,
      } = data || {};

      this.entryData = [
        { label: "来店量", value: core.count },
        { label: "首次进店量", value: core.first },
        { label: "再次进店量", value: core.again },
        { label: "接待得分", value: core.total },
      ];

      this.regionData = region.sort((a, b) => a.rank - b.rank);
      this.areaData = area;

      this.scorePercent = [b_percent, c_percent, d_percent];
      const liuS = [
        {
          value: a / 100,
          ref: "chart1",
          color: "#75d3ff",
        },
        {
          value: b / 100,
          ref: "chart2",
          color: "#a3ebff",
        },
        {
          value: c / 100,
          ref: "chart3",
          color: "#30a891",
        },
        {
          value: d / 100,
          ref: "chart4",
          color: "#4f9ab7",
        },
      ];
      this.province = province.map((o) => ({
        name: o.name,
        value: [o.lng, o.lat],
        num: o.total,
        itemStyle: { color: this.getColor(o.total) },
      }));
      this.initEcharts(this.province);
      this.liquidFill(liuS);
      this.loading = false;
    },
    liquidFill(liuS) {
      liuS.forEach((item) => {
        let liu = echarts.init(this.$refs[item.ref]);
        let option = {
          series: [
            {
              type: "liquidFill", //类型
              data: [item.value], //数据是个数组 数组的每一项的值是0-1
              outline: {
                //show: true , //是否显示轮廓 布尔值
                borderDistance: 4, //外部轮廓与图表的距离 数字
                itemStyle: {
                  borderColor: item.color, //边框的颜色
                  borderWidth: 4, //边框的宽度
                  shadowBlur: 0, //外部轮廓的阴影范围 一旦设置了内外都有阴影
                  shadowColor: "#000", //外部轮廓的阴影颜色
                },
              },
              backgroundStyle: {
                color: "transparent", //图表的背景颜色
              },
              itemStyle: {
                opacity: 1, //波浪的透明度
                shadowBlur: 10, //波浪的阴影范围
                shadowColor: item.color, //阴影颜色,
              },
              label: {
                fontSize: 16,
                formatter: function (params) {
                  return Math.floor(params.data * 100);
                },
              },
              color: [item.color], //水波的颜色 对应的是data里面值
              shape: "circle", //水填充图的形状 circle默认圆形  rect圆角矩形  triangle三角形  diamond菱形  pin水滴状 arrow箭头状  还可以是svg的path
              //图表相对于盒子的位置 第一个是水平的位置 第二个是垂直的值 默认是[50%,50%]是在水平和垂直方向居中 可以设置百分比 也可以设置具体值
              radius: "90%", //图表的大小 值是圆的直径 可以是百分比 也可以是具体值 100%则占满整个盒子 默认是40%; 百分比下是根据宽高最小的一个为参照依据
              amplitude: 3, //振幅 是波浪的震荡幅度 可以取具体的值 也可以是百分比 百分比下是按图标的直径来算
              waveLength: "50%", //波的长度 可以是百分比也可以是具体的像素值  百分比下是相对于直径的 取得越大波浪的起伏越小
              phase: 0, //波的相位弧度 默认情况下是自动
            },
          ],
        };
        liu.setOption(option);
      });
    },
    initEcharts(dataValue) {
      this.myEchart = echarts.init(this.$refs.myEchart);

      let option = {
        tooltip: {
          show: false,
        },
        geo: {
          map: "china",
          roam: false, // 一定要关闭拖拽
          zoom: 1.23,
          center: [105, 36], // 调整地图位置
          label: {
            normal: {
              show: false, //关闭省份名展示
              fontSize: "10",
              color: "#fff",
            },
            emphasis: {
              show: true,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#0462fd",
              borderColor: "#0462fd",
              borderWidth: 1, //设置外层边框
              shadowBlur: 5,
              shadowOffsetY: 3,
              shadowOffsetX: 0,
              shadowColor: "#0462fd",
            },
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            roam: false,
            zoom: 1.23,
            center: [105, 36],
            // geoIndex: 1,
            // aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: true,
                color: "#fff",
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#0d0059",
                borderColor: "#389dff",
                borderWidth: 0.5,
              },
              emphasis: {
                areaColor: "#17008d",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
          {
            name: "",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: dataValue,

            symbol: "circle",
            symbolSize: 8,
            hoverSymbolSize: 10,
            tooltip: {
              formatter(value) {
                return value.data.name + "<br/>" + value.data.num;
              },
              show: true,
            },
            encode: {
              value: 2,
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",

              period: 9,
              scale: 5,
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "right",
              show: false,
            },
            itemStyle: {
              shadowBlur: 2,
              shadowColor: "#333",
            },
            zlevel: 1,
          },
        ],
      };

      this.myEchart.setOption(option);
    },
    getColor(v) {
      if (v < 60) {
        return "red";
      }
      if (v >= 80) {
        return "green";
      }
      return "yellow";
    },
    getBgColor(v, mode = null) {
      const { ft } = getLightColor(v);
      return `color:${ft}`;
    },
    jumpToRegion(item) {
      // this.$router.push({
      //   path: "/board/region",
      //   query: { region_id: item.id },npm
      // });
        let query={...this.$store.state.query,region_id:item.id};
      jump("/board/region",this, query);
  
    },
    jumpToArea(item) {
       let query={...this.$store.state.query,area_id:item.id};
      jump("/board/area",this, query);
    
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/index.scss";
.container {
  background: url("../../assets/board_bg.png") no-repeat;

  background-size: cover;

  background-position: bottom center;
  .content {
    .item {
      display: flex;
      flex-direction: column;
      margin-top: 0.185185rem /* 10/54 */;
      &.item_1 {
        width: 9.666667rem /* 522/54 */;
        height: 7.907407rem /* 427/54 */;
        background: url("../../assets/battle_map.png");
        position: relative;
        background-size: cover;
      }
      &.item_2 {
        width: 9.666667rem /* 522/54 */;
        height: 5.240741rem /* 283/54 */;
        background: url("../../assets/board.png");

        background-size: cover;

        .itemDetail {
          width: 50%;
        }
      }
      &.item_3 {
        width: 9.666667rem /* 522/54 */;
        height: 4.592593rem /* 248/54 */;
        background: url("../../assets/board_table.png");

        background-size: cover;
        .liuD {
          display: flex;
          justify-content: space-evenly;
          color: #fff;
          align-items: center;
          text-align: center;
          font-size: 0.277778rem /* 15/54 */;
          white-space: nowrap;
          .liuItem {
            .liuChart {
              width: 100%;
              height: 2.111111rem /* 114/54 */;
            }
            &:nth-child(1) {
              color: #75d3ff;
              width: 2.111111rem /* 114/54 */;
            }
            &:nth-child(3) {
              color: #a3ebff;
              width: 1.592593rem /* 86/54 */;
            }
            &:nth-child(5) {
              color: #30a891;
              width: 1.592593rem /* 86/54 */;
            }
            &:nth-child(7) {
              color: #4f9ab7;
              width: 1.592593rem /* 86/54 */;
            }
          }
        }
      }
      &.item_4 {
        width: 9.666667rem /* 522/54 */;
        border: 1px solid #144cb9;
        box-shadow: 0px 0px 5px #144cb9 inset;
        color: #00d5e9;

        font-size: 0.222222rem /* 12/54 */;
        padding: 0.185185rem /* 10/54 */;
        th {
          max-width: 0.851852rem /* 46/54 */;
        }
      }
      &.item_5 {
        width: 9.666667rem /* 522/54 */;

        border: 1px solid #144cb9;
        box-shadow: 0px 0px 5px #144cb9 inset;

        color: #00d5e9;
        background-size: cover;
        font-size: 0.222222rem /* 12/54 */;

        padding: 0.185185rem /* 10/54 */;
      }

      .title {
        margin-left: 0.481481rem /* 26/54 */;
        color: #00d5e9;
        font-size: 0.407407rem /* 22/54 */;
        height: 0.962963rem /* 52/54 */;

        line-height: 0.962963rem /* 52/54 */;
      }
    }
  }
}
.table tr td {
  background: rgba(0, 6, 106, 0.5);
  border-bottom: 2px solid #000350;
}
.mapTips {
  position: absolute;

  z-index: 100000;
  bottom: 0.185185rem /* 10/54 */;
  left: 0.185185rem /* 10/54 */;
  color: #fff;
  // width: 2.055556rem /* 111/54 */;
  height: 1.851852rem /* 100/54 */;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.148148rem /* 8/54 */ 0.259259rem /* 14/54 */;
  font-size: 0.222222rem /* 12/54 */;
  border-radius: 10px;
  border: 1px solid #0f6ebf;
}
.mapTips p span {
  margin-left: 0.092593rem /* 5/54 */;
  font-size: 0.222222rem; /* 12/54 */
}
.mapTips p:nth-child(1) span {
  color: green;
}
.mapTips p:nth-child(2) span {
  color: yellow;
}
.mapTips p:nth-child(3) span {
  color: red;
}
</style>